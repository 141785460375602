import axios from 'axios';

const server_base_url = 'https://gtp.haploiwms.io';
const access_token = '940977254288bc1:aa3f7c7d80eed78';
// 33ba76518d5efa3:67f2553b3559463
class SuggestionServices {
 

    submitSuggestion = (suggestion) => {
        return axios.post(`${server_base_url}/api/resource/Suggestions`, suggestion, {
          headers: {
            Authorization: `token ${access_token}`
          }
        });
      };
    
      // Method to fetch escalation types
      fetchEscalationTypes = () => {
        return axios.get(`${server_base_url}/api/resource/Escalation Matrix?filters=[["Escalation Matrix","status","=","Active"]]&order_by=sequence`, {
          headers: {
            Authorization: `token ${access_token}`
          }
        });
      };

      uploadImage = (file, newFileName) => {
        const formData = new FormData();
        formData.append('file', file, newFileName);
        
        return axios.post(`${server_base_url}/api/method/upload_file`, formData, {
          headers: {
            Authorization: `token ${access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      };
}

export default new SuggestionServices();