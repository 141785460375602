import "./FeedbackFormScreen.css";
import React, { useState, useEffect } from "react";
import SuggestionServices from "./Service/suggestionService";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";

export const FeedbackForm = () => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [selectedValue, setSelectedValue] = useState("Visitors");
  const [escalationType, setEscalationType] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState("");
  const [validIssue, setvalidIssue] = useState(true);
  const [selectedTower, setSelectedTower] = useState("");
  const [validTower, setvalidTower] = useState(true);
  const [readConditions, setReadConditions] = useState(false);
  const [name, setName] = useState("");
  const [validName, setvalidName] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validNumber, setvalidNumber] = useState(true);
  const [floorNumber, setFloorNumber] = useState("");
  const [textField, setTextField] = useState("");
  const [validText, setValidText] = useState(true);
  const [email, setEmail] = useState("");
  const [validEmail, setvalidEmail] = useState(true);
  const [token, setToken] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [verified, setverified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [elevatorNumber, setElevatorNumber] = useState("");
  const towerLists = {
    "General Complaints": ["Tower B", "Tower E"],
    "Food Court": ["Tower B", "Tower E"],
    Cleanliness: [
      "Tower A",
      "Tower B",
      "Tower C",
      "Tower D",
      "Tower E",
      "MLCP",
      "Common Area",
    ],
    Events: ["Tower A", "Tower B", "Tower C", "Tower D", "Tower E", "MLCP"],
  };

  // const [showDialog, setShowDialog] = useState(false);
  //POST api call
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setInputs((prevInputs) => ({
  //     ...prevInputs,
  //     [name]: value
  //   }));
  // };

  //   const handleRatingChange = (index) => {
  //     setRating(index + 1);
  // };

  const handleFileChange = (event) => {
    // Access the selected file from the event
    const file = event.target.files[0];
    setSelectedFile(file);
    if (isImageFile(file)) {
      // The selected file is an image file
      console.log("Selected file is an image file");
    } else {
      Swal.fire({
        // title: "Oops...",
        text: "Only image file is allowed. Please select an image.",
        // icon: "error",
      }).then(() => {
        // Clear the selected file
        event.target.value = null;
        setSelectedFile(null);
      });
      // The selected file is not an image file
      console.log("Selected file is not an image file");
    }
  };

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleIssueChange = (event) => {
    setSelectedIssue(event.target.value);
  };

  const handleTowerChange = (event) => {
    setSelectedTower(event.target.value);
  };

  const setTokenFunc = (getToken) => {
    setToken(getToken);
  };

  const isImageFile = (file) => {
    // Check if the file type starts with 'image/'
    return file.type.startsWith("image/");
  };

  function onChange(value) {
    console.log("Captcha value", value);
    setverified(true);
  }

  useEffect(() => {
    console.log("getting");
    // Fetch escalation types when the component mounts
    setEscalationType([]);
    SuggestionServices.fetchEscalationTypes()
      .then((response) => {
        // Extract data from the response and update the state

        const data = response.data["data"];
        console.log("Data:", data); // Check the structure of the data
        setEscalationType(data.map((item) => item.name));
        console.log(escalationType); // Extracting names from objects and updating state
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching escalation types:", error);
      });
  }, []); // Empty dependency array ensures that this effect runs only once on component mount

  function getCurrentTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // to reset form
  function resetForm() {
    setRating(0);
    setHover(0);
    setSelectedValue("Visitors");
    setSelectedIssue("");
    setvalidIssue(true);
    setSelectedTower("");
    setvalidTower(true);
    setReadConditions(false);
    setName("");
    setvalidName(true);
    setCompanyName("");
    setPhoneNumber("");
    setvalidNumber(true);
    setFloorNumber("");
    setTextField("");
    setValidText(true);
    setEmail("");
    setToken("");
    setverified(false);
    setSelectedFile(null);
    setElevatorNumber("");
    setvalidEmail(true);
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const showTermsAndConditions = () => {
    const content = `
      <div class="content-wrapper">
        <h3>Confidentiality:</h3>
        <p>Any information provided in the feedback form will be treated as confidential and will not be disclosed to third parties without consent.</p>
        <h3>Ownership of Feedback:</h3>
        <p>By providing feedback, you agree that the owner of the commercial office space has the right to use and analyze the feedback for improvement purposes.</p>
        <h3>Accuracy of Information:</h3>
        <p>You agree that the feedback provided is accurate to the best of your knowledge and does not contain any false or misleading information.</p>
        <h3>Use of Personal Information:</h3>
        <p>Any personal information provided in the feedback form will be used only for the purpose of contacting you regarding the feedback and will not be shared with third parties without consent.</p>
        <h3>Feedback Content:</h3>
        <p>Feedback should be constructive and respectful. Any feedback containing inappropriate language, discriminatory remarks, or irrelevant content may be disregarded.</p>
        <h3>No Guarantee of Action:</h3>
        <p>While the owner of the commercial office space values your feedback, they reserve the right to take or not take action based on the feedback received.</p>
        <h3>Modification of Terms:</h3>
        <p>The owner of the commercial office space reserves the right to modify these terms and conditions at any time without prior notice.</p>
        <h3>Contact Information:</h3>
        <p>For any inquiries or concerns regarding the feedback form or these terms and conditions, please contact helpdesk.</p>
      </div>
    `;

    Swal.fire({
      title: "Terms and Conditions",
      html: content,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: "Ok",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // setEmail(email.trim());
    // const trimmedEmail = email.trim();
    // setEmail(trimmedEmail);
    // console.log(`.e${email}..`);
    // Get current date and time
    let currentDate = getCurrentDate();
    let currentTime = getCurrentTime();

    const newFileName = `FB-${currentDate}- ${currentTime}.png`;
    // specify the new file name here
    console.log(email);
    if (selectedValue === "Visitors") {
      if (
        name.length === 0 ||
        phoneNumber.length < 10 ||
        (email.length > 0 && !isValidEmail(email)) ||
        textField.trim() === ""
      ) {
        if (name.length === 0) {
          setvalidName(false);
        } else {
          setvalidName(true);
        }

        if (phoneNumber.length < 10) {
          setvalidNumber(false);
        } else {
          setvalidNumber(true);
        }

        if (email.length > 0 && !isValidEmail(email)) {
          setvalidEmail(false); // Set invalid email state
          return; // Return to stop further processing
        } else {
          setvalidEmail(true); // Set valid email state
        }

        if(textField.trim()===""){
          setValidText(false);
        }else{
          setValidText(true);
        }

        return;
      }
    } else {
      if (
        name.length === 0 ||
        phoneNumber.length < 10 ||
        (selectedTower.length === 0 &&
          !["Elevators"].includes(selectedIssue)) ||
        selectedIssue.length === 0 ||
        (email.length > 0 && !isValidEmail(email)) ||
        textField.trim() === ""
      ) {
        if (name.length === 0) {
          setvalidName(false);
        } else {
          setvalidName(true);
        }

        if (phoneNumber.length < 10) {
          setvalidNumber(false);
        } else {
          setvalidNumber(true);
        }

        if (selectedTower.length === 0) {
          setvalidTower(false);
        } else {
          setvalidTower(true);
        }

        if (selectedIssue.length === 0) {
          setvalidIssue(false);
        } else {
          setvalidIssue(true);
        }

        if (email.length > 0 && !isValidEmail(email)) {
          setvalidEmail(false); // Set invalid email state
          return; // Return to stop further processing
        } else {
          setvalidEmail(true); // Set valid email state
        }

        if(textField.trim()===""){
          setValidText(false);
        }else{
          setValidText(true);
        }

        return;
      }
    }

    // Validate select field
    // if (selectedValue === 'Parkite' && !selectedIssue) {
    //   alert('Please select a category type.');
    //   return;
    // }
    // // Populate the suggestion object with form data and current date/time
    const suggestion = {
      rating: selectedValue === "Visitors" ? rating : 0,
      suggestion: textField,
      email: email,
      feedback_type: selectedValue === "Visitors" ? "Suggestions" : "Tenant",
      date: currentDate,
      time: currentTime,
      full_name: name,
      company: companyName,
      phone_number: phoneNumber,
      tower:
        selectedIssue === "Elevators"
          ? `${selectedTower} Elevator No: ${elevatorNumber || "---"}`
          : selectedTower,
      floor_number: floorNumber,
      escalation_type: selectedIssue,
      vendor_check: selectedValue === "Parkite" ? "Vendor" : "General",
      photo: "",
      resolution_time: "00:00:00",
      resolution_date: currentDate,
      response_time: "00:00:00",
      response_date: currentDate,
    };
    setLoading(true);
    //submit form
    if (selectedFile != null) {
      //upload image and submit
      // Upload the image file first
      SuggestionServices.uploadImage(selectedFile, newFileName)
        .then((uploadResponse) => {
          // Handle successful image upload
          console.log("File uploaded successfully:", uploadResponse);

          //Attach the file location
          suggestion.photo = `/files/${newFileName}`;

          // Once the image is uploaded, submit the suggestion
          SuggestionServices.submitSuggestion(suggestion)
            .then((submitResponse) => {
              // Handle successful submission

              console.log("Suggestion submitted successfully:", submitResponse);
              Toast.fire({
                icon: "success",
                title: "Thank you for the feedback",
              });
              setLoading(false);
              resetForm();
              console.log(loading);

              // window.close();
            })
            .catch((submitError) => {
              // Handle error in suggestion submission

              console.error("Error submitting suggestion:", submitError);
              Swal.fire({
                title: "Oops...",
                text: "Unable to submit feedback. Please try again.",
                // icon: "error",
              });
              setLoading(false);
            });
        })
        .catch((uploadError) => {
          // Handle error in image upload

          console.error("Error uploading file:", uploadError);
          Swal.fire({
            title: "Oops...",
            text: "Unable to upload image. Please try again.",
            // icon: "error",
          });
          setLoading(false);
        });
    } else {
      //submit without image
      // Call the API service to submit the suggestion
      SuggestionServices.submitSuggestion(suggestion)
        .then((response) => {
          // Handle successful submission
          console.log("logging");
          Toast.fire({
            icon: "success",
            title: "Thank you for the Feedback",
          });
          // Swal.fire({
          //   title: "success",
          //   text: "Thank you for the Feedback.",
          //   // icon: "error",
          // });
          setLoading(false);
          resetForm();
          console.log(loading);
          // reset();
        })
        .catch((error) => {
          // Handle error
          // alert("Failed to submit suggestion. Please try again later.");

          Swal.fire({
            title: "Oops...",
            text: "Unable to submit feedback try again.",
            // icon: "error"
          });
          setLoading(false);
          console.error("Error submitting suggestion:", error);
          //  return;
        });
    }
  };

  return (
    <div>
      {/* <img src={Image1} alt='im'/> */}
      <form onSubmit={handleSubmit} className="Form">
        <div>
          <h6 style={{ align: "centre" }} className="Heading">
            Global Technology Park
          </h6>
        </div>

        {/* <br></br> */}

        {selectedValue !== "Parkite" && (
          <div>
            <label id="rating">How was your experience?</label>
            <div className="star-rating">
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    className={index <= (hover || rating) ? "on" : "off"}
                    onClick={() => setRating(index)}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(rating)}
                  >
                    <span className="star">&#9733;</span>
                  </button>
                );
              })}
            </div>
          </div>
        )}

        {/* Radio Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
          className="Type"
        >
          {["Visitors", "Parkite"].map((option) => (
            <div key={option} style={{ marginRight: "10px" }}>
              <input
                type="radio"
                className="Type"
                id={option}
                value={option}
                checked={selectedValue === option}
                onChange={handleValueChange}
              />
              <label htmlFor={option} id="TypeLabel">
                {option}
              </label>
            </div>
          ))}
        </div>

        {/* Dropdown */}
        {selectedValue === "Parkite" && (
          <div>
            {/* <label htmlFor="issue">Category Type:</label> */}
            <select
              id="issue"
              value={selectedIssue}
              onChange={handleIssueChange}
              className={`selectField${validIssue ? "valid" : "invalid"}`}
            >
              <option value="">Select Category Type</option>
              {escalationType.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
            <br></br>
            {/* Add validation error message */}
            {validIssue === false && (
              <div className="errorMsg">
                <label className="validation">Please select an option.</label>
              </div>
            )}
          </div>
        )}

        {/* Render the dropdown only if selectedValue is "Parkite" */}
        {selectedValue === "Parkite" &&
          selectedIssue &&
          ["Elevators"].includes(selectedIssue) && (
            <div>
              <input
                type="text"
                value={elevatorNumber}
                onChange={(e) => setElevatorNumber(e.target.value)}
                placeholder={`Elevator Number`}
                className="textFieldvalid"
              />
              <br />
            </div>
          )}
        <div>
          {/* Render the dropdown only if selectedValue is "Parkite" */}
          {selectedIssue && (
            <div>
              <select
                id="tower"
                value={selectedTower}
                onChange={(e) => setSelectedTower(e.target.value)}
                className={`selectField${validTower ? "valid" : "invalid"}`}
              >
                <option value="">Select Tower</option>
                {(
                  towerLists[selectedIssue] || [
                    "Tower A",
                    "Tower B",
                    "Tower C",
                    "Tower D",
                    "Tower E",
                    "MLCP",
                  ]
                ).map((tower, index) => (
                  <option key={index} value={tower}>
                    {tower}
                  </option>
                ))}
              </select>
              <br />
              {validTower === false && (
                <div className="errorMsg">
                  <label className="validation">Please select an option.</label>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Other Text Fields */}
        <div>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            className={`textField${validName ? "valid" : "invalid"}`}
          />

          {/* Add validation error message */}
          {validName === false && (
            <div className="errorMsg">
              <label className="validation">Required.</label>
            </div>
          )}
        </div>
        <div>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder={selectedValue === "Parkite" ? "Company Name" : "From"}
            className="textFieldvalid"
          />
        </div>
        <div>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Phone Number"
            className={`textField${validNumber ? "valid" : "invalid"}`}
          />
          {/* <br></br> */}
          {/* Add validation error message */}

          {validNumber === false && (
            <div className="errorMsg">
              <label className="validation">Enter a valid Number.</label>
            </div>
          )}
        </div>
        <div>
          {selectedValue === "Parkite" && (
            <>
              <div>
                <input
                  type="text"
                  value={floorNumber}
                  onChange={(e) => setFloorNumber(e.target.value)}
                  placeholder="Floor Number"
                  className="textFieldvalid"
                />
              </div>
            </>
          )}

          <div>
            <textarea
              value={textField}
              onChange={(e) => setTextField(e.target.value)}
              placeholder={
                selectedValue === "Visitors"
                  ? "What can we do better?"
                  : "Details of the Issue..."
              }
              className={`textDetails${validText ? "Valid" : "InValid"}`}
            />
            {/* Add validation error message */}
            {validText === false && (
              <div className="errorMsg">
                <label className="validation">Required.</label>
              </div>
            )}
          </div>
        </div>
        <div>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            placeholder="Email"
            className={`textField${validEmail ? "valid" : "invalid"}`}
          />
          {validEmail === false && (
            <div className="errorMsg">
              <label className="validation">Enter a valid Email.</label>
            </div>
          )}
        </div>

        <div className="attachbody">
          {/* <label>Attach Image:</label> */}
          <input type="file" onChange={handleFileChange} id="attachimage" />
          <label htmlFor="attachimage" id="attachimagelabel">
            Attach Image
          </label>
          <h5 id="h5">
            {" "}
            {selectedFile === null
              ? "No file selected"
              : selectedFile.name.length > 13 // Set your desired maximum length
              ? selectedFile.name.substring(0, 7) +
                "..." +
                selectedFile.name.substring(selectedFile.name.length - 5)
              : selectedFile.name}
          </h5>
        </div>

        {/* Checkbox */}
        <div className="TCD">
          <input
            type="checkbox"
            checked={readConditions}
            onChange={(e) => setReadConditions(e.target.checked)}
          />
          <label id="TC" onClick={showTermsAndConditions}>
            I agree to the terms and conditions
          </label>
        </div>

        {/* <GoogleReCaptchaProvider reCaptchaKey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'>
          <GoogleReCaptcha
            className="google-recaptcha-custom-class"
            onVerify={setTokenFunc}
            refreshReCaptcha={refreshReCaptcha}
          />
        </GoogleReCaptchaProvider> */}

        <div>
          <ReCAPTCHA
            style={{ align: "center", marginLeft: 20, marginBottom: 20 }}
            sitekey="6LdLvsApAAAAADG4rFGq1DsByncP7BXMoihUPYEQ"
            onChange={onChange}
            //badge='bottomleft'
          />
        </div>
        <div className="Submit">
          <input
            type="submit"
            value="Submit"
            disabled={!readConditions || !verified || loading}
            id="submit"
          />
        </div>
      </form>
    </div>
  );
};
export default FeedbackForm;
